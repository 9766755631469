import { getEnviroment } from 'env';
import { AxiosRequest } from '../common/AxiosRequest';
import { HALLink } from '../common/HALLinkResolver';
import {AccessToken} from '@okta/okta-auth-js';

export enum GatewayStateEnum {
  Iris = 'IRIS',
  Legacy = 'ZITCOM',
}

export type TelematicsDevice = {
  serialNumber: string;
  firmwareVersion: number;
  aux: string;
  accessControlMode: string;
  gatewayState: GatewayStateEnum;
  _links: {
    configurations: HALLink[];
    installation: HALLink;
    installations: HALLink;
  };
};

const telematicsDeviceApiUrl = getEnviroment().endpoints.telematicsDevice + '/telematics-devices';

function getTelematicsDevice(
  accessToken: AccessToken | undefined,
  serialNumber: string
): Promise<TelematicsDevice> {
  return AxiosRequest.get(
    accessToken,
    `${telematicsDeviceApiUrl}/${serialNumber}`,

    result => {
      return result.data as TelematicsDevice;
    }
  );
}

function getTelematicsDeviceAssetId(
    accessToken: AccessToken | undefined,
    serialNumber: string
): Promise<string> {
  return AxiosRequest.get(
      accessToken,
      `${telematicsDeviceApiUrl}/${serialNumber}/asset-id`,

      result => {
        return result.data as string;
      }
  );
}

function firmwareUpdate(accessToken: AccessToken | undefined,
                         serialNumber: string) {
  const firmwareUpdateApiUrl = telematicsDeviceApiUrl + `/firmware-update/${serialNumber}`;

  return AxiosRequest.post(accessToken, `${firmwareUpdateApiUrl}`,{});
}

export const LegacyTelematicsDeviceApi = {
  getTelematicsDevice, getTelematicsDeviceAssetId, firmwareUpdate
};
