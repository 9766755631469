import * as React from 'react';
import './CanDataList.scss';
import { List } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { Timeline } from "./Timeline";
import { CanData } from "../../stores/CanData/CanDataApi";


interface Props {
  canData: CanData[];
}

export const CanDataList: React.FC<Props> = props => {
  return (
    <List className="can-data-list">
      {props.canData.map(canData => (
        <ListItem key={canData.variableId} className="can-data-list-item">
          <ListItemText
            primary={
              <Typography component="span" variant="subtitle2">
                {canData.sensorName}
              </Typography>
            }
            secondary={
              <>
                {canData.latestData && canData.latestData.value != null? (
                  <>
                    {canData.latestData.timestamp && <Typography component={'span'} variant="caption">
                      {canData.latestData.timestamp.toLocaleString()}
                    </Typography>}
                    <Typography component={'span'} variant="body1">
                      {canData.latestData.value} {canData.unit}
                    </Typography>
                    {canData.data && canData.data.length > 1 && (
                      <Timeline data={canData.data.filter(d => d.type === "SensorFloatValue" || d.type === "SensorBooleanValue").map(d => [d.timestamp.getTime(), d.value])} height={60} />
                    )}
                    </>
                ) : (
                  'No data'
                )}
              </>
            }
          />
        </ListItem>
      ))}
    </List>
  );
}
