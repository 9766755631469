import {AuthState} from "@okta/okta-auth-js";
import {IOState, DeviceStateApi} from "../TelematicsDevice/DeviceStateApi";

export const convertToDesiredState = (currentDesiredIOState: IOState | undefined,
                                      input1Name?: string,
                                      input2Name?: string,
                                      input3Name?: string,
                                      input4Name?: string,
                                      input5Name?: string,
                                      input6Name?: string) => {

  let desiredState: IOState = {};

  if( input1Name && input1Name != currentDesiredIOState?.io?.input1?.name) {
    desiredState = {io: {...desiredState.io, input1: {name: input1Name}}}
  }

  if( input2Name && input2Name != currentDesiredIOState?.io?.input2?.name) {
    desiredState = {io: {...desiredState.io, input2: {name: input2Name}}}
  }

  if( input3Name && input3Name != currentDesiredIOState?.io?.input3?.name) {
    desiredState = {io: {...desiredState.io, input3: {name: input3Name}}}
  }

  if( input4Name && input4Name != currentDesiredIOState?.io?.input4?.name) {
    desiredState = {io: {...desiredState.io, input4: {name: input4Name}}}
  }

  if( input5Name && input5Name != currentDesiredIOState?.io?.input5?.name) {
    desiredState = {io: {...desiredState.io, input5: {name: input5Name}}}
  }

  if( input6Name && input6Name != currentDesiredIOState?.io?.input6?.name) {
    desiredState = {io: {...desiredState.io, input6: {name: input6Name}}}
  }

  return desiredState;
}

export const saveInputNames = (
  authState: AuthState | null,
  deviceId: string,
  currentDesiredIOState: IOState | undefined,
  input1Name?: string,
  input2Name?: string,
  input3Name?: string,
  input4Name?: string,
  input5Name?: string,
  input6Name?: string, )
  : Promise<any> => {

  const desiredState: IOState = convertToDesiredState(currentDesiredIOState, input1Name, input2Name, input3Name, input4Name, input5Name, input6Name);

  return DeviceStateApi.updateState(
        authState?.accessToken,
        deviceId,
        desiredState
  );

  return Promise.resolve()
}