import React from 'react';
import {RouteComponentProps} from 'react-router';
import {Typography} from '@material-ui/core';
import './grid.css';
import UnitOverview from './components/UnitOverview/UnitOverview';
import {InputFilterProvider} from './stores/InputFiltering/InputFilteringProvider';
import {OutputModeProvider} from './stores/OutputMode/OutputModeProvider';
import {LegacyTelematicsDeviceProvider} from './stores/LegacyTelematicsDevice/LegacyTelematicsDeviceProvider';
import {Branding, BrandingContext} from 'components/BrandingProvider';
import UnitSearch from './components/UnitSearch/UnitSearch';
import {DeviceAccessControlProvider} from './stores/DeviceAccessControl/DeviceAccessControlProvider';
import {AutoRefreshState} from "./components/DeviceState/AutoRefreshState";
import {AccessibilityProvider} from "./stores/AccessibilityProvider/AccessibilityProvider";

interface UnitPageProps {
    serial: string;
}

type Props = RouteComponentProps<UnitPageProps>;

export const Unitlookup: React.FC<Props> = () => {
    const brandingContext: Branding = React.useContext(BrandingContext);

    return (
        <div>
            <Typography variant="h5" gutterBottom className="fullWidth">
                {brandingContext.welcome}
            </Typography>
              <InputFilterProvider>
                <LegacyTelematicsDeviceProvider>
                  <AccessibilityProvider>
                      <AutoRefreshState />
                      <OutputModeProvider>
                        <DeviceAccessControlProvider>
                          <UnitSearch/>
                          <UnitOverview/>
                        </DeviceAccessControlProvider>
                      </OutputModeProvider>
                  </AccessibilityProvider>
                </LegacyTelematicsDeviceProvider>
              </InputFilterProvider>
        </div>
    );
};
