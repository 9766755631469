import axios from 'axios';
import { getEnviroment } from 'env';
import { defaultAxiosOptions } from '../common/DefaultAxiosOptions';
import { AccessToken } from "@okta/okta-auth-js";

const telematicsDeviceV2Endpoint = getEnviroment().endpoints.telematicsDeviceV2;


export type FirmwareState = {
  firmware?: {
    version: string
  }
}

export enum OutputModeEnum {
  HIGH = 'HIGH',
  LOW = 'LOW',
}

export enum DigitalKeyModeEnum {
  DISABLED = 'DISABLED',
  UNLOCKED_FOR_ALL = 'UNLOCKED_FOR_ALL',
  UNLOCKED_FOR_ALL_FIXED = 'UNLOCKED_FOR_ALL_FIXED',
}

export enum Gateway {
  IRIS = 'iris',
  LEGACY = 'legacy'
}

export enum ReportProtocolEnum {
  P3 = '3',
  P6 = '6'
}

export type BaseMetadataState = {
  updatedAt: string,
  gateway: Gateway
}

export type InputState = {
  name?: string,
  thresholds?: {
    high?: string,
    low?: string
  },
  invert?: {
    enabled: string
  }
}

export type InputStateMetadata = {
  thresholds?: BaseMetadataState,
  invert?: BaseMetadataState
}

export type IOState = {
  io?: {
    output1?: {
      mode: OutputModeEnum,
      period: string
    },
    inputFiltering?: {
      enabled: string
    },
    input1?: InputState,
    input2?: InputState,
    input3?: InputState,
    input4?: InputState,
    input5?: InputState,
    input6?: InputState
  },
}

export type IOMetadataState = {
  io?: {
    output1?: BaseMetadataState,
    inputFiltering?: BaseMetadataState,
    input1?: InputStateMetadata,
    input2?: InputStateMetadata,
    input3?: InputStateMetadata,
    input4?: InputStateMetadata,
    input5?: InputStateMetadata,
    input6?: InputStateMetadata
  },
}

export type AccessControlState = {
  accessControl?: {
      mode: DigitalKeyModeEnum
  },
}

export type AccessControlMetadataState = {
  accessControl?: BaseMetadataState,
}

export type ProtocolState = {
  protocol?: {
    report?: {
      version: ReportProtocolEnum
    }
  }
}

export type Profile = {
  profile?: {
    id: string,
    name?: string
  },
}

export type CanbusState = {
  canbus?: Profile & {
    can1?: Profile,
    can2?: Profile
  }
}

export type ModbusState = {
  modbus?: Profile
}

export type CanbusMetadataState = {
  canbus?: {
    profile?: BaseMetadataState,
    can1?: {
      profile?: BaseMetadataState
    },
    can2?: {
      profile?: BaseMetadataState
    }
  }
}

export type ModbusMetadataState = {
  modbus?: {
    profile?: BaseMetadataState
  }
}

export enum ActivityDetectionType {
  ACTIVITY_DETECTED ='ACTIVITY_DETECTED',
  NO_ACTIVITY_DETECTED = 'NO_ACTIVITY_DETECTED',
  ACTIVITY_UNKNOWN = 'ACTIVITY_UNKNOWN'
}

export type CanActivityDetectionStateV1 = {
  canbus?: {
      activityDetection?: ActivityDetectionType
  }
}

export type CanActivityDetectionStateV2  = {
  canbus?: {
    activity?: {
      canActivityState?: ActivityDetectionType
    }
  }
}

export type CanActivityDetectionStateV3 = {
  canbus?: {
    activityDetection?: {state: ActivityDetectionType},
    can1? : {
      activityDetection?: {state: ActivityDetectionType}
    },
    can2? : {
      activityDetection?: {state: ActivityDetectionType}
    }
  }
}

export enum AccessControlDeviceType {
  UNKNOWN= 'UNKNOWN',
  NO_ACCESSORY_CONNECTED = 'NO_ACCESSORY_CONNECTED',
  IBUTTON_READER = 'IBUTTON_READER',
  DUALID = 'DUALID',
  DUALID_II = 'DUALID_II',
  K300 = 'K300'
}

export type AccessControlDeviceState = {
  accessControlDevice?: {
    type: AccessControlDeviceType
  }
}

export const deviceStateType = {
  desired: "desired",
  reported: "reported",
} as const;
export type DeviceStateType = (typeof deviceStateType)[keyof typeof deviceStateType];

export type ReportedStateEntities = FirmwareState & IOState & CanbusState & ModbusState & CanActivityDetectionStateV1 & CanActivityDetectionStateV2 & CanActivityDetectionStateV3 & AccessControlDeviceState & AccessControlState & ProtocolState;
export type DesiredStateEntities = FirmwareState & IOState & CanbusState & ModbusState & AccessControlState & ProtocolState;
export type DesiredMetadataEntities = IOMetadataState & CanbusMetadataState & ModbusMetadataState &  AccessControlMetadataState;

export interface DeviceState {
  reported: ReportedStateEntities;
  desired: DesiredStateEntities;
  metadata: {
    desired: DesiredMetadataEntities
  }
}

export type DocumentationParameter = {
  id: number,
  name: string
}

export const configurationAccessType = {
  VIEW: "VIEW",
  LOCKED_FOR_CHANGES: 'LOCKED_FOR_CHANGES',
  EDIT: 'EDIT',
} as const;
export type ConfigurationAccessType = (typeof configurationAccessType)[keyof typeof configurationAccessType];

export type CanStateDocumentation = {
  canbus?: {
    profiles: DocumentationParameter[],
    readonly?: boolean,
    access: ConfigurationAccessType,
    accessReason: string
  }
}

export type ModbusStateDocumentation = {
  modbus?: {
    profiles: DocumentationParameter[]
  }
}

export type AccessControlStateDocumentation = {
  accessControl?: {
    readonly: boolean
  }
}

export type DeviceStateDocumentation = CanStateDocumentation & ModbusStateDocumentation & AccessControlStateDocumentation;

function getState(
  accessToken: AccessToken | undefined,
  deviceId: string
): Promise<DeviceState> {
    return axios.get<any>(`${telematicsDeviceV2Endpoint}/devices/${deviceId}/state`,
      defaultAxiosOptions(accessToken))
    .then(state => {
      return state.data as DeviceState;
    })
    .catch(error => {
      throw new Error(
        `An unexpected error occurred while trying to fetch device state of '${deviceId} - server returned no data`
      );
    });
}

function getStateDocumentation(
  accessToken: AccessToken | undefined,
  deviceId: string
): Promise<DeviceStateDocumentation> {
  return axios.get<any>(`${telematicsDeviceV2Endpoint}/devices/${deviceId}/state-documentation`,
    defaultAxiosOptions(accessToken))
    .then(doc => {
      let accessControl = doc.data.accessControl;
      let canStateDoc : CanStateDocumentation = {}
      let modbusStateDoc = {}

      let canProfilesData = doc.data.canbus?.profile || doc.data.canbus?.can1?.profile;
      if (canProfilesData && canProfilesData.parameters.length === 1) {
        const allowedValuesDetails = canProfilesData.parameters[0].allowedValuesDetails;
        const profiles: DocumentationParameter[] = Object.values(allowedValuesDetails);
        canStateDoc = { canbus: { 
          profiles, 
          readonly: canProfilesData.readonly, 
          access: canProfilesData.access, 
          accessReason: canProfilesData.accessReason } };
      }

      let modbusProfilesData = doc.data.modbus?.profile
      if (modbusProfilesData && modbusProfilesData.parameters.length === 1) {
        const allowedValuesDetails = modbusProfilesData.parameters[0].allowedValuesDetails;
        const profiles: DocumentationParameter[] = Object.values(allowedValuesDetails);
        modbusStateDoc = { modbus: { profiles } };
      }

      let finalDoc: DeviceStateDocumentation = {accessControl, ...canStateDoc, ...modbusStateDoc};
      return finalDoc;
    })
    .catch(error => {
      // Most likely fails becuase the devices does not support TLV
      return Promise.resolve({});
    });
}

function updateState(accessToken: AccessToken | undefined,
                     deviceId: string,
                     desiredState: DesiredStateEntities) {
  return axios.patch<any>(`${telematicsDeviceV2Endpoint}/devices/${deviceId}/state`,
                     { desired: desiredState },
                          defaultAxiosOptions(accessToken))
      .catch(error => {
        throw new Error(
            `An unexpected error occurred while trying to update device state of '${deviceId}`
        );
      });
}

export const DeviceStateApi = { getState, getStateDocumentation, updateState };
