import React from 'react';
import {BaseUnitInfo, ClassicUnitApi, UnitInfo} from './ClassicUnitApi';
import {ClassicUnitVerifyToolApi} from './ClassicUnitVerifyToolApi';
import {StatusContextValue, useContextStatus} from '../common/ContextStatus';
import {useOktaAuth} from '@okta/okta-react';
import {DeviceState, DeviceStateApi, DeviceStateDocumentation, ReportProtocolEnum} from "./DeviceStateApi";
import {DeviceIdentity, DeviceIdentityApi, DeviceTypeEnum} from "./DeviceIdentityApi";
import {isFirmwareGreaterThanOrEqualTo} from "./FirmwareComparison";
import { DeviceTelemetry, DeviceTelemetryApi } from './DeviceTelemetryApi';
import {saveCanProfiles} from "../CanProfile/CanProfileProvider";
import {saveModbusProfile} from "../ModbusProfile/ModbusProfileProvider";
import {saveInputNames} from "../InputName/InputNameProvider";

type UnitInfoContextValue = StatusContextValue & {
  unitInfo: UnitInfo;
  setUnitInfo: (unitInfo: UnitInfo) => void;

  lastSavedUnitInfo: UnitInfo;
  setLastSavedUnitInfo: (unitInfo: UnitInfo) => void;

  state: DeviceState;
  setState: (deviceState: DeviceState) => void;

  stateDocumentation: DeviceStateDocumentation;
  setStateDocumentation: (deviceStateDocumentation: DeviceStateDocumentation) => void;

  identity: DeviceIdentity;
  setIdentity: (deviceIdentity: DeviceIdentity) => void;

  telemetry: DeviceTelemetry;
  setTelemetry: (deviceTelemetry: DeviceTelemetry) => void;

  can1ProfileId?: string;
  setCan1ProfileId: (canProfileId?: string) => void;

  can2ProfileId?: string;
  setCan2ProfileId: (can2ProfileId?: string) => void;

  modbusProfileId?: string;
  setModbusProfileId: (modbusProfileId?: string) => void;

  hasTlvSupport: boolean;
  setHasTlvSupport: (hasTlvSupport: boolean) => void;

  input1Name?: string;
  setInput1Name: (input1Name?: string) => void;

  input2Name?: string;
  setInput2Name: (input2Name?: string) => void;

  input3Name?: string;
  setInput3Name: (input3Name?: string) => void;

  input4Name?: string;
  setInput4Name: (input4Name?: string) => void;

  input5Name?: string;
  setInput5Name: (input5Name?: string) => void;

  input6Name?: string;
  setInput6Name: (input6Name?: string) => void;
};

const UnitInfoContext = React.createContext<UnitInfoContextValue | undefined>(
  undefined
);

type UnitInfoProviderProps = {
  children: React.ReactNode;
};

const TelematicsDeviceProvider = (props: UnitInfoProviderProps) => {
  const [unitInfo, setUnitInfo] = React.useState();
  const [lastSavedUnitInfo, setLastSavedUnitInfo] = React.useState();
  const [contextStatus, setContextStatus] = React.useState({
    loading: false,
    saving: false,
    hasContent: false
  });
  const [deviceState, setDeviceState] = React.useState();
  const [deviceStateDocumentation, setDeviceStateDocumentation] = React.useState();
  const [deviceIdentity, setDeviceIdentity] = React.useState();
  const [deviceTelemetry, setDeviceTelemetry] = React.useState();
  const [can1ProfileId, setCan1ProfileId] = React.useState();
  const [can2ProfileId, setCan2ProfileId] = React.useState();
  const [modbusProfileId, setModbusProfileId] = React.useState();
  const [hasTlvSupport, setHasTlvSupport] = React.useState<boolean>(false);

  const [input1Name, setInput1Name] = React.useState();
  const [input2Name, setInput2Name] = React.useState();
  const [input3Name, setInput3Name] = React.useState();
  const [input4Name, setInput4Name] = React.useState();
  const [input5Name, setInput5Name] = React.useState();
  const [input6Name, setInput6Name] = React.useState();

  const value: UnitInfoContextValue = React.useMemo(
    () => ({
      unitInfo,
      setUnitInfo,
      lastSavedUnitInfo,
      setLastSavedUnitInfo,
      contextStatus,
      setContextStatus,
      state: deviceState,
      setState: setDeviceState,
      stateDocumentation: deviceStateDocumentation,
      setStateDocumentation: setDeviceStateDocumentation,
      identity: deviceIdentity,
      setIdentity: setDeviceIdentity,
      telemetry: deviceTelemetry,
      setTelemetry: setDeviceTelemetry,
      can1ProfileId,
      setCan1ProfileId,
      can2ProfileId,
      setCan2ProfileId,
      modbusProfileId,
      setModbusProfileId,
      hasTlvSupport,
      setHasTlvSupport,
      input1Name,
      setInput1Name,
      input2Name,
      setInput2Name,
      input3Name,
      setInput3Name,
      input4Name,
      setInput4Name,
      input5Name,
      setInput5Name,
      input6Name,
      setInput6Name
    }),
    [unitInfo, lastSavedUnitInfo, contextStatus, deviceState, deviceStateDocumentation, deviceIdentity, deviceTelemetry, can1ProfileId, can2ProfileId, modbusProfileId, hasTlvSupport, setHasTlvSupport, input1Name, setInput1Name, input2Name, setInput2Name, input3Name, setInput3Name, input4Name, setInput4Name, input5Name, setInput5Name, input6Name, setInput6Name]
  );
  return <UnitInfoContext.Provider value={value} {...props} />;
};

const useDeviceContext = () => {
  const context = React.useContext(UnitInfoContext);
  const { authState } = useOktaAuth();

  if (!context) {
    throw new Error(
      'useUserInfoContext must be used within a UserInfoProvider'
    );
  }

  const status = useContextStatus(context);

  const {
    unitInfo,
    setUnitInfo,
    lastSavedUnitInfo,
    setLastSavedUnitInfo,
    contextStatus,
    state,
    setState,
    stateDocumentation,
    setStateDocumentation,
    identity,
    setIdentity,
    telemetry,
    setTelemetry,
    can1ProfileId,
    setCan1ProfileId,
    can2ProfileId,
    setCan2ProfileId,
    modbusProfileId,
    setModbusProfileId,
    hasTlvSupport,
    setHasTlvSupport,
    input1Name,
    setInput1Name,
    input2Name,
    setInput2Name,
    input3Name,
    setInput3Name,
    input4Name,
    setInput4Name,
    input5Name,
    setInput5Name,
    input6Name,
    setInput6Name
  } = context;

  function load(
    serialNumber: string
  ) : Promise<{id: string }> {
    status.loadingStarted();
    setCan1ProfileId(undefined);
    setCan2ProfileId(undefined);
    setModbusProfileId(undefined);

    let unitId = '';

    return DeviceIdentityApi.getIdentity(authState?.accessToken, serialNumber)
      .then(identity => {
        setIdentity(identity);

        const telemetryPromise = DeviceTelemetryApi.getTelemetry(authState?.accessToken, identity.id);
        const statePromise = loadState(identity);
        const unitInfoPromise = ClassicUnitApi.getUnitInfo(authState?.accessToken, identity.serialNumber);
        const stateDocumentationPromise = DeviceStateApi.getStateDocumentation(authState?.accessToken, identity.id);

        return Promise.all([telemetryPromise, statePromise, unitInfoPromise, stateDocumentationPromise])
          .then(([telemetry, state, unitResult, stateDocumentation]) => {
            setTelemetry(telemetry);
            setInput1Name(unitResult.input1Name) // TODO, read from the reported state
            setInput2Name(unitResult.input2Name) // TODO, read from the reported state
            setInput3Name(unitResult.input3Name) // TODO, read from the reported state
            setInput4Name(unitResult.input4Name) // TODO, read from the reported state
            setInput5Name(state.reported.io?.input5?.name)
            setInput6Name(state.reported.io?.input6?.name)
            setUnitInfo({ ...unitResult});
            setStateDocumentation(stateDocumentation);
            setLastSavedUnitInfo({...unitResult });

            if( !state.reported.firmware?.version) {
              setHasTlvSupport(false);
            }
            else {
              setHasTlvSupport(
                identity.deviceType === DeviceTypeEnum.TU700
                || identity.deviceType === DeviceTypeEnum.GRIFFIN
                || (
                  isFirmwareGreaterThanOrEqualTo(state.reported.firmware.version, 63, 8)
                  && (identity.deviceType === DeviceTypeEnum.TU500
                    || identity.deviceType === DeviceTypeEnum.TU501
                    || identity.deviceType === DeviceTypeEnum.TU600)))
            }
            setCan1ProfileId(isGriffinDevice(identity) ? state.desired.canbus?.can1?.profile?.id : state.desired.canbus?.profile?.id);
            setCan2ProfileId(state.desired.canbus?.can2?.profile?.id);
            setModbusProfileId(state.desired.modbus?.profile?.id);
            status.succeed();

            unitId = unitResult.id;

            return Promise.resolve({ id: unitId });
          });
      })
      .catch(err => {
        status.failed(err.message);
        return Promise.reject();
      });
  }

  const loadState = (deviceIdentity: DeviceIdentity) => {
    return DeviceStateApi.getState(authState?.accessToken, deviceIdentity.id)
      .then(stateResult => {
        setState(stateResult);
        return Promise.resolve(stateResult);
      })
      .catch(err => {
        throw new Error("Fetch of state failed");
      });
  }

  const reloadState = () => {
    loadState(identity)
  }

  const isGriffinDevice = (deviceIdentity: DeviceIdentity) => {
    const deviceType = deviceIdentity.deviceType;
    return deviceType === DeviceTypeEnum.TU700 || deviceType === DeviceTypeEnum.GRIFFIN;
  };

  const save = () => {
    status.savingStarted();

    let promises = [];
    if (unitInfo && unitInfo.mtecHasCanSupport) {
      promises.push(saveCanProfiles(authState, can1ProfileId, can2ProfileId, identity.id, state, unitInfo, isGriffinDevice(identity)));
    }

    if(unitInfo && isGriffinDevice(identity)) {
      promises.push(saveModbusProfile(authState, modbusProfileId, identity.id, state))
    }

    promises.push(saveInputNames(authState, identity.id, state.desired, input1Name, input2Name, input3Name, input4Name, input5Name, input6Name));

    promises.push(ClassicUnitVerifyToolApi.update(authState?.accessToken, {
      id: unitInfo.id,
      input1Name: unitInfo.input1Name, // Need to set it to the original value else it will fail with a permission error. TODO: Remove it when we have fully transisioned to Device State
      input2Name: unitInfo.input2Name, // --||--
      input3Name: unitInfo.input3Name, // --||--
      input4Name: unitInfo.input4Name, // --||--
      name: unitInfo.name,
      totalKm: unitInfo.totalKm,
      totalRun1: unitInfo.totalRun1,
      categoryId: unitInfo.categoryId,
      referenceNumber: unitInfo.referenceNumber,
      note: unitInfo.note
    }));

    return Promise.all(promises)
      .then(() => {
        setLastSavedUnitInfo(unitInfo);
        status.succeed('Unit was updated');
        loadState(identity);
      })
      .catch(err => status.failed(err.message, 'Could not save'));
  };

  const setName = (name: string) => setUnitInfo({ ...unitInfo, name });
  const setTotalKm = (totalKm: number) => setUnitInfo({ ...unitInfo, totalKm });
  const setTotalRun1 = (totalRun1: number) =>
    setUnitInfo({ ...unitInfo, totalRun1 });
  const setCategoryId = (categoryId: string | undefined) =>
    setUnitInfo({ ...unitInfo, categoryId });
  const setReferenceNumber = (referenceNumber: string) =>
    setUnitInfo({ ...unitInfo, referenceNumber });
  const setNote = (note: string) => setUnitInfo({ ...unitInfo, note });
  const setValues = (values: BaseUnitInfo) =>
    setUnitInfo({ ...unitInfo, ...values });

  return {
    unitInfo,
    identity,
    state,
    stateDocumentation,
    telemetry,
    hasTlvSupport,
    can1ProfileId,
    can2ProfileId,

    lastSavedCanProfileId: lastSavedUnitInfo && lastSavedUnitInfo.canProfileId,

    load,
    reloadState,
    save,

    setName,
    setTotalKm,
    setTotalRun1,
    setCan1ProfileId,
    setCan2ProfileId,
    setModbusProfileId,
    setCategoryId,
    setReferenceNumber,
    setNote,
    input1Name,
    input2Name,
    input3Name,
    input4Name,
    input5Name,
    input6Name,
    setInput1Name,
    setInput2Name,
    setInput3Name,
    setInput4Name,
    setInput5Name,
    setInput6Name,
    isGriffinDevice,

    setUnitInfoValues: setValues, // Used when multiple updates are needed

    contextStatus,
    dismissError: status.dismissError,
    dismissSuccess: status.dismissSuccess
  };
};

export { TelematicsDeviceProvider, useDeviceContext };