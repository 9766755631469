import axios from 'axios';
import { getEnviroment } from 'env';
import { defaultAxiosOptions } from '../common/DefaultAxiosOptions';
import {AccessToken} from "@okta/okta-auth-js";

const unitInfoEndpoint = getEnviroment().endpoints.updateUnitVerifyTool;

interface UpdateClassicUnitVerifyToolApiRequest {
  id?: string;
  name?: string;
  referenceNumber?: string;
  categoryId?: string;
  totalKm?: number;
  totalRun1?: number;
  note?: string;
  input1Name?: string;
  input2Name?: string;
  input3Name?: string;
  input4Name?: string;
}

function update(
  accessToken: AccessToken | undefined,
  info: UpdateClassicUnitVerifyToolApiRequest
): Promise<any> {
  if (info && info.id) {
    return axios.post<void>(
      `${unitInfoEndpoint}?format=json`,
      {
        Id: info.id,
        Note: info.note || '',
        Name: info.name || '',
        CategoryId: info.categoryId || '',
        TotalKm: info.totalKm ? info.totalKm.toString() : 0,
        ReferenceNumber: info.referenceNumber || '',
        TotalRun1: info.totalRun1 || 0,
        Input1Name: info.input1Name,
        Input2Name: info.input2Name,
        Input3Name: info.input3Name,
        Input4Name: info.input4Name
      },
      defaultAxiosOptions(accessToken)
    ).catch(err => {
      if( err.response.status === 403) {
        throw new Error("You do not have access to save this unit");
      }
      throw err;
    });
  } else {
    throw new Error('An UnitInfo value including id must be provided');
  }
}

export const ClassicUnitVerifyToolApi = { update };
