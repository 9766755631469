export const GET_LATEST_CAN_DATA = `query LatestAdvancedSensors($assetId: ID!, $systemOfMeasurement: SystemOfMeasurement, $before: Cursor, $after: Cursor, $last: Int, $first: Int) {
  asset(id: $assetId) {
    advancedSensors {
      latest(
        before: $before
        after: $after
        first: $first
        last: $last
        input: {systemOfMeasurement: $systemOfMeasurement}
      ) {
        edges {
          node {
            unit {
              unit
              dataType
            }
            data {
              ... on SensorFloatValue {
                timestamp
                floatValue
                __typename
              }
              ... on SensorBooleanValue {
                timestamp
                booleanValue
                __typename
              }
              ... on SensorStringValue {
                timestamp
                stringValue
                __typename
              }
              ... on SensorBinaryValue {
                timestamp
                binaryValue
                __typename
              }
              ... on SensorRangeValue {
                timestamp
                rangeValue {
                  min
                  max
                }
                __typename
              }
              ... on SensorUnknownValue {
                timestamp
                __typename
              }
            }
            classifier {
              ... on AdvancedSensorCanClassifier {
                type
                identifier {
                  variableId
                  sensorName
                }
              }
              ... on AdvancedSensorReportMessageClassifier {
                type
                identifier {
                  sensorType
                  customSensorName
                }
              }
            }
          }
          cursor
        }
        pageInfo {
          count
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
  }
}`

export const GET_HISTORICAL_CAN_DATA = `query HistoricalAdvancedSensors($assetId: ID!, $interval: IntervalInput!, $granularity: DataGranularity, $ids: [AdvancedSensorClassifierIdInput!]!, $systemOfMeasurement: SystemOfMeasurement, $before: Cursor, $after: Cursor, $last: Int, $first: Int) {
  asset(id: $assetId) {
    advancedSensors {
      historical(
        before: $before
        after: $after
        first: $first
        last: $last
        input: {interval: $interval, granularity: $granularity, systemOfMeasurement: $systemOfMeasurement, ids: $ids}
      ) {
        edges {
          node {
            unit {
              unit
              dataType
            }
            data {
              ... on SensorFloatValue {
                timestamp
                floatValue
                __typename
              }
              ... on SensorBooleanValue {
                timestamp
                booleanValue
                __typename
              }
              ... on SensorStringValue {
                timestamp
                stringValue
                __typename
              }
              ... on SensorBinaryValue {
                timestamp
                binaryValue
                __typename
              }
              ... on SensorRangeValue {
                timestamp
                rangeValue {
                  min
                  max
                }
                __typename
              }
              ... on SensorUnknownValue {
                timestamp
                __typename
              }
            }
            classifier {
              ... on AdvancedSensorCanClassifier {
                type
                identifier {
                  variableId
                  sensorName
                }
              }
              ... on AdvancedSensorReportMessageClassifier {
                type
                identifier {
                  sensorType
                  customSensorName
                }
              }
            }
          }
          cursor
        }
        pageInfo {
          count
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
  }
}`
