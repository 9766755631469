import React from 'react';
import {StatusContextValue, useContextStatus} from '../common/ContextStatus';
import {OutputModeApi, OutputModeResult} from './OutputModeApi';
import {useOktaAuth} from '@okta/okta-react';
import {useDeviceContext} from "../TelematicsDevice/TelematicsDeviceProvider";
import {DeviceStateApi, OutputModeEnum} from "../TelematicsDevice/DeviceStateApi";
import {useAccessibilityContext} from "../AccessibilityProvider/AccessibilityProvider";

type OutputModeContextValue = StatusContextValue & {
  enabled: boolean;
  setEnabled: (enabled: boolean) => void;
};

const OutputModeContext = React.createContext<
  OutputModeContextValue | undefined
>(undefined);

type OutputModeProviderProps = {
  children: React.ReactNode;
};

const OutputModeProvider = (props: OutputModeProviderProps) => {
  const [enabled, setEnabled] = React.useState<boolean>(false);

  const [contextStatus, setContextStatus] = React.useState({
    loading: false,
    saving: false,
    hasContent: false
  });

  const value: OutputModeContextValue = React.useMemo(
    () => ({
      enabled,
      setEnabled,
      contextStatus,
      setContextStatus
    }),
    [enabled,contextStatus]
  );
  return <OutputModeContext.Provider value={value} {...props} />;
};

const useOutputModeContext = () => {
  const context = React.useContext(OutputModeContext);
  const { authState } = useOktaAuth();
  const { identity} = useDeviceContext();
  const { accessibility } = useAccessibilityContext();

  if (!context) {
    throw new Error(
      'useOutputModeContext must be used within an OutputModeProvider'
    );
  }

  const status = useContextStatus(context);
  const { enabled, setEnabled, contextStatus } = context;

  const load = (serialNumber: string | undefined): Promise<void> => {
    status.loadingStarted();

    if (serialNumber === undefined) {
      setEnabled(false);
      return Promise.resolve();
    } else {
      return OutputModeApi.get(authState?.accessToken, serialNumber)
        .then((response: OutputModeResult) => {
          setEnabled(response.enabled);
          status.succeed();
        })
        .catch(err => {
          status.failed(err.message, 'Could not load the output mode');
          return Promise.reject();
        });
    }
  };

  const save = (unitId: string, newEnabled: boolean) => {
    if (!accessibility?.outputMode.visible) {
      return Promise.reject('User not allowed to toggle the output mode');
    }

    if( accessibility.outputMode.writeSource === "IRIS" ) {
      DeviceStateApi.updateState(authState?.accessToken, identity.id,
        {
          io: {
            output1: {
              mode: newEnabled ? OutputModeEnum.HIGH : OutputModeEnum.LOW,
              period: "PERMANENT"
            }
          }
        })
        .then(() => {
          setEnabled(newEnabled);
        })

    }
    else { // Legacy flow
      return OutputModeApi.update(authState?.accessToken, {
        unitId,
        enabled: newEnabled
      })
        .then(() => {
          setEnabled(newEnabled);
        })
    }
  };

  return {
    enabled,

    load,
    save,

    contextStatus,
    dismissError: status.dismissError,
    dismissSuccess: status.dismissSuccess
  };
};

export { OutputModeProvider, useOutputModeContext };
